<template>
  <div class="">
    <div class="flex justify-space-between">
        <div class="box self-flex-end"><h2 class="mv-0">MEMBERS</h2></div>
        <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex column gaps">
      <div class="box flex gaps">
        <el-input class="box grow" ref="search" v-model="search"></el-input>
      </div>
      <div class="box">
        <vue-scroll class="scroll-area flex"
          :settings="settings"
          :style="{height: scrollHeight + 'px'}">
          <div class="box grow">
            <app-waiver-row
              :item="c"
              :isChecked="c.waiver"
              :isHome="false"
              v-for="(c, index) in filtered" :key="index + '-waiver'"></app-waiver-row>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppWaiverRow from '../components/WaiverRow'

export default {
  name: 'Waivers',

  components: {
    AppWaiverRow
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      search: '',
      settings: {
        maxScrollbarLength: 60
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'basketWaiverIds',
      'lastMembersIds'
    ]),
    filtered () {
      return this.members.filter(item => {
        return (this.basketWaiverIds.includes(item._id)) ||
         (this.lastMembersIds.includes(item._id)) ||
         (this.search.length >= 3 &&
         ((item.hasOwnProperty('first') && item.first.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('surname') && item.surname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('email') && item.email.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('phone') && item.phone.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)))
      }).map(m => {
        if (this.basketWaiverIds.includes(m._id)) {
          return Object.assign({}, m, { waiver: true })
        } else {
          return Object.assign({}, m, { waiver: false })
        }
      })
    },
    scrollHeight () {
      return this.innerHeight - 395
    }
  },

  methods: {
    searchFocus () {
      this.search = ''
      if (JSON.parse(localStorage.getItem('cursor'))) {
        this.$refs.search.focus()
      }
    }
  },
  activated: async function () {
    this.searchFocus()
  }
}
</script>
